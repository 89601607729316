import { AccessibilityHelpers } from 'js/components/Util/Accessibility.js';
import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
const createFocusTrap = require('focus-trap/index.js');

let accessibilityHelpersInstance = new AccessibilityHelpers();

export class Header {
  constructor(mobileBP = 767) {
    this.headerEl = document.getElementById('Header');
    this.headerMenuButton = document.querySelector('.js-menu-dropdown-trigger');
    this.headerMenuItems = document.querySelectorAll('.Header-menuLink');
    this.headerMenuDropdown = document.querySelector('.js-menu-dropdown');
    this.initClickHandlers();

    this.focusTrap = createFocusTrap('.js-menu-dropdown', {
      onActivate: () => {
        this.handleHeaderTabIndexes(0);
        this.headerMenuDropdown.classList.add('is-active');
      },
      onDeactivate: () => {
        this.handleHeaderTabIndexes(-1);
        this.headerMenuButton.classList.remove('is-active');
        this.headerMenuDropdown.classList.remove('is-active');
      },
      clickOutsideDeactivates: true
    });
  }

  initClickHandlers() {
    this.headerMenuButton.addEventListener('click', _ => {
      accessibilityHelpersInstance.toggleAriaState(this.headerMenuButton, 'expanded');
      if (this.headerMenuButton.classList.toggle('is-active')) {
        this.focusTrap.activate();
      } else {
        this.focusTrap.deactivate();
      }
    });

  }

  handleHeaderTabIndexes(tabIndex) {
    accessibilityHelpersInstance.setTabIndex(this.headerMenuItems, tabIndex);
  }
}
