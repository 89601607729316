import 'babel-polyfill';

// uncomment if you need to do expand/collapse:
import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { Yext } from 'js/components/Soy2js/Soy2js.js'; // NOTE: if not using soy2js, replace with window.Yext = window.Yext || {};

import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js';
import { StateList } from 'js/directory/modules/StateList.js';

Global.init();
GoogleAnalytics.enableAutotracking('yext');
StateList.init();
