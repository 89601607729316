export class StateList {
  static init() {
    $(document).ready(() => {
      let citiesWidget = document.querySelector('.js-top-cities');
      let citiesRaw = document.querySelector('.js-top-cities-raw');
      let citiesEl = citiesRaw.querySelectorAll('.js-city');
      let citiesList = [];
      for (let cityEl of citiesEl) {
        let cityLink = new CityLink($(cityEl).data('name'), parseInt($(cityEl).data('loccount')), $(cityEl).data('url'), cityEl.parentElement);
        citiesList.push(cityLink);
      }
      if (citiesList.length > 40) {
        citiesList.sort((a, b) => {
          if (a.count > b.count)
            return -1;
          else if (a.count == b.count)
            return 0;
          else
            return 1;
        });
        citiesList = citiesList.slice(0, 40);
      }

      citiesList.sort((a, b) => {
        if (a.name < b.name)
          return -1;
        else if (a.name == b.name) 
          return 0;
        else
          return 1;
      })

      for (let city of citiesList) {
        let listEl = city.parent;
        citiesWidget.appendChild(listEl);
      }

      citiesRaw.remove();
    });
  }
}

export class CityLink{
  constructor(name, count, url, parent) {
    this.name = name;
    this.count = count;
    this.url = url;
    this.parent = parent;
  }
}